

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { VueEditor, Quill  } from "vue2-editor";
// import Quill from "quill";
import MessageModal from "@/components/modals/message-modal";
import axios from "axios";

import { authHeader } from "../../../helpers/auth-header";	

export default {
  name: "Editor", 
  components: {
    // VueEditor,
    Layout,
    PageHeader,
    VueEditor, 
    MessageModal
  },
  data() {
    return {
      items: [
        {
            text: "Agreement"
        },
      ],
      editorContent: '',
      editorOption: {},
      agreement_template: [
        { indicator: "<landlord_business></landlord_business>", explanation: "Text displayed if landlord have fill in business info" },
        { indicator: "<landlord_personal></landlord_personal>", explanation: "Text displayed for landlord's personal info" },
        { indicator: "<tenant_business></tenant_business>", explanation: "Text displayed for tenant's business info" },
        { indicator: "<tenant_personal></tenant_personal>", explanation: "Text displayed for tenant's personal info" },
        { indicator: "<renewable></renewable>", explanation: "Text displayed when selected Yes for Renewable" },
        { indicator: "<unrenewable></unrenewable>", explanation: "Text displayed when selected No for Renewable" },
      ],
      agreement_replacement: [
        { indicator: "###landlord_company_name###", explanation: "Landlord Company Name" },
        { indicator: "###landlord_company_no###", explanation: "Landlord Company Registration No." },
        { indicator: "###landlord_company_address### ", explanation: "Landlord Company Address" },
        { indicator: "###landlord_name###", explanation: "Landlord's Name" },
        { indicator: "###landlord_ic###", explanation: "Landlord's IC" },
        { indicator: "###landlord_address###", explanation: "Landlord's Address" },
        { indicator: "###tenant_company_name###", explanation: "Tenant's Company Name" },
        { indicator: "###tenant_company_no###", explanation: "Tenant's Company Registration No." },
        { indicator: "###tenant_company_address###", explanation: "Tenant's Company Address" },
        { indicator: "###tenant_name###", explanation: "Tenant's Name" },
        { indicator: "###tenant_address###", explanation: "Tenant's Address" },
        { indicator: "###property_type_name###", explanation: "Property Type" },
        { indicator: "###property_size###", explanation: "Property Size" },
        { indicator: "###property_siz_unite###", explanation: "Property Size Unit" },
        { indicator: "###property_address###", explanation: "Property Address" },
        { indicator: "###property_title_desc###", explanation: "Property Title Description" },
        { indicator: "###tenure###", explanation: "Tenure" },
        { indicator: "###start###", explanation: "Rental Start Date" },
        { indicator: "###end###", explanation: "Rental End Date" },
        { indicator: "###rental###", explanation: "Rental Fee" },
        { indicator: "###rental_due_date###", explanation: "Rental Due Date" },
        { indicator: "###stamp###", explanation: "Stamp Docs Will be added here" },
        { indicator: "###rental_deposit###", explanation: "Rental Deposit" },
        { indicator: "###renewable_notice_month###", explanation: "Prior Notice Renewable Month" },
        { indicator: "###fee_allocation###", explanation: "Fee Allocation" },
        { indicator: "###amenity_page###", explanation: "Amenity Pictures" },
      ],
      displayMessage: false,
      button_clicked: false,
    }
  },
  async mounted() {
    var Block = Quill.import('blots/block');
    console.log(Block);
    // class OrderedList extends Block {
    //   static create(value) {
    //     let node = super.create();
    //     if (value) {
    //       node.setAttribute('data-value', value);
    //       node.textContent = value + '. ';
    //     }
    //     return node;
    //   }

    //   static formats(domNode) {
    //     return domNode.getAttribute('data-value') || true;
    //   }

    //   format(name, value) {
    //     if (name === 'list' && value === 'ordered') {
    //       this.domNode.removeAttribute('data-value');
    //     } else {
    //       super.format(name, value);
    //     }
    //   }
    // }
    // Quill.register('blots/block', OrderedList)
    await this.getData();
  },
  methods: {
    async save() {
      if (this.button_clicked) return ;
      this.button_clicked = true;
      const result = await axios.put(`${process.env.VUE_APP_APIURL}/agreement/1`, 
        {
          "template": this.editorContent,
        }
      , {
        headers: authHeader(),
      }).catch( function (error) {
        console.log(error.message);
        this.button_clicked = false;
      });

      if (result.data.code === 'success') {
        this.$refs.messageModal.showModal('Update Success');
        this.button_clicked = false;
      } else if (result.data.code === 'permission_denied') {
        this.button_clicked = false;
        this.$refs.messageModal.showModal('Permission Denied');
      }
    },
    async getData() {
      const result = await axios.get(`${process.env.VUE_APP_APIURL}/agreement/1`, {
        headers: authHeader(),
      }).catch( function (error) {
        alert("Load agreemment error:" + error.message);
      });

      if (result.data.code === 'success') {
        const {data} = result.data;
        this.editorContent = data.template
      } else if (result.data.code == 'permission_denied') {
        this.$refs.messageModal.showModal('Permission Denied');
      }
    }
  }
};
</script>

<style lang="css" scoped>
.content-indicator {
  border: 1px solid rgba(0,0,0,.125);
  padding: 0.5rem;
  margin-bottom: 0.4rem;
}

.highlight_code {
  width: 40%;
  font-family: "Nunito Sans";
  font-weight: 800;
}

.seperator {
  width: 20px;
}

.explaination {
  width: calc(60% - 20px);
}
</style>

<template>
  <Layout>
    <MessageModal ref="messageModal"/>
      <PageHeader title="Agreement Template" :items="items" />
      <div>
        <b-form-checkbox v-model="displayMessage" name="check-button" switch class="mb-2">
          Text Indicator: <b>{{ displayMessage ? 'Displayed' : 'Hidden' }}</b>
        </b-form-checkbox>
      </div>
      <!-- <div class="content-indicator">
        <div class="row">
          
          <h4 class="subTitle-font">Text </h4>

          
          <div class="col-6 d-flex" v-for="temp,index in agreement_template" :key="index">
            <div class="highlight_code w-50">
              {{ temp.indicator }}
            </div>
            <div class="seperator">-</div>
            <div class="explaination" >
              {{ temp.explanation }}
            </div>
          </div> 


        </div>

      </div> -->
      <div class="row">
        <div class="row-col-12 col-sm-8">
          <div class="content-indicator" v-if="displayMessage">
            <h4 class="subTitle-font mt-2">Agreement Scope</h4>
            <div class="d-flex" v-for="temp,index in agreement_template" :key="index">
              <div class="highlight_code ">
                {{ temp.indicator }}
              </div>
              <div class="seperator">-</div>
              <div class="explaination" >
                {{ temp.explanation }}
              </div>
            </div>

            <h4 class="subTitle-font mt-2">Agreement Text Replacement</h4>
            <div class="d-flex" v-for="temp,index in agreement_replacement" :key="index">
              <div class="highlight_code ">
                {{ temp.indicator }}
              </div>
              <div class="seperator">-</div>
              <div class="explaination" >
                {{ temp.explanation }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="save" class="btn btn-orange mb-2 ml-auto d-block">Save</button>
      <div>
        <VueEditor v-model="editorContent"/>
      </div>
  </Layout>
</template>